/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/reveal.js@5.0.5/dist/reveal.min.js
 * - /npm/reveal.js@5.0.5/plugin/markdown/markdown.min.js
 * - /npm/reveal.js@5.0.5/plugin/math/math.min.js
 * - /npm/reveald3@2.0.0/reveald3.min.js
 * - /npm/reveal.js-mermaid-plugin@2.3.0/plugin/mermaid/mermaid.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
